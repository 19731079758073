angular.module('sq.user.auth', ['sq.user.model', 'sq.user.auth.tokens',
'sq.user.auth.token.interceptor', 'sq.common.errors.service',
'sq.user.auth.interceptor', 'sq.user.auth.values', 'ng'])
.factory('Auth', AuthFactory);

//TODO: define error API, such as UnauthroizedError for use with Auth.logout event broadcast
function AuthFactory(userTokens, AuthValues, authHttpService,
  User, errorFactory, $rootScope, $http, $q, $log, $location, $cacheFactory, $timeout) {
  const Auth = {};
  
  const eventPrefix = 'sq.user.auth:';
  Auth.eventPrefix = eventPrefix;

  let _loginPromise = null;
  let _tokenRefreshAttempts = 0;
  const _maxLoginAttempts = 5;

  const errors = {
    sessionExpired: new errorFactory.unauthorized('Session Expired'),
    sessionExpiredAndOffline: new errorFactory.unauthorized("Session Expired and Internet connection seems down"),
    sessionMissing: new errorFactory.unauthorized('No session token found'),
    attemptsExceeded: new errorFactory.unauthorized('Session refesh attempts exceeded'),
  };

  /*
  * @public
  */
  let _user = {};

  Object.defineProperty(Auth, 'user', {
    get: function getuser() {
      // unfortunatey using a setter here won't trigger dirty checkign on model
      return _user;
    },
    set: function setuser(data) {
      if (data.hasOwnProperty('name') &&
        data.hasOwnProperty('role') ) {
        _user = angular.copy(data);
      } else {
        $log.error('user object must have name and role');
      }
    }, enumberable: true,
  });

  Auth.clear = function clear() {
    _loginPromise = null;
    _tokenRefreshAttempts = 0;
    _user = {};
    userTokens.clear();
    $cacheFactory.get('$http').removeAll();
  };
  /**
   * Authenticate user and save token
   *
   * @param  {Object}   user     - login info
   * @param  {Function} callback - optional
   * @return {Promise}
   */

  var _authBaseRoute = AuthValues.authBaseRoute;
  // TODO: allow a queue, so that user may try to log in while guest being 
  // requested
  Auth.login = function login(user, strategy) {
    if (_loginPromise) {
      return _loginPromise;
    }

    if (Auth.isLoggedIn() ) {
      $log.warn('Auth.login called, but user was already logged in, logging out');
      Auth.logout();
    }

    var loginStrategy = strategy || 'local';
    var params = {};

    if (!user) {
      loginStrategy = 'guest';
    } else {
      params.email = user.email;
      params.password = user.password;
    }

    var route = _authBaseRoute + loginStrategy;

    _loginPromise = $http.post(route, params, {cache: true}).then(
    function(response) {
      _loginPromise = null;
      return _completeLoginAsync(response.data);
    }, function(err) {
      _loginPromise = null;
      return $q.reject(err);
    });

    return _loginPromise;
  };

  //TODO: think about storing a promise reference, to prevent multiple parallel calls
  // to the refresh portion of this function
  let sessionExpiredTimeout;
  function _completeLoginAsync(data, logoutIfNoValidToken) {
    var deferred = $q.defer();

    if (Auth.isLoggedIn() && !userTokens.isExpired() ) {
      deferred.resolve(Auth.user);
      return deferred.promise;
    }

    if (data) {
      userTokens.idToken = data; 
    }

    // User hasn't been stored yet... if we succeed broadcast loggedIn
    if (userTokens.idToken ) {
      if ( !userTokens.isExpired() ) {
        const wasLoggedIn = Auth.isLoggedIn();

        Auth.user = userTokens.decode();

        // TODO: check that decode worked (will throw if doesn't, better way?)
        if(!wasLoggedIn) {
          $rootScope.$broadcast(eventPrefix + 'loggedIn' );
        }

        // Must be run after login, in order to ensure that requestes that 
        // were rejected due to 401' are retried
        // Should not be within Auth.isLoggedIn() check, because user may
        // have been isLoggedIn(), but had an expired token
        // which would have led to unauthorized requests
        // Because http is async, it also may not be sufficient to just check
        // for !userTokens.isExpired()
        authHttpService.loginConfirmed();

        // Reset the number of attempts; this gets iterated only if refresh fails
        _tokenRefreshAttempts = 0;

        deferred.resolve(Auth.user);

        return deferred.promise;
      }

      // 2nd argument indicates whether the expiration was permanent,
      // or whether it can be retried
      if(sessionExpiredTimeout) {
        $timeout.cancel(sessionExpiredTimeout);
      }

      sessionExpiredTimeout = $timeout( () => {
        $rootScope.$broadcast(eventPrefix + 'sessionExpired', false);
      }, 100);

      userTokens.refreshIdTokenAsync().then((response) => {
        $log.debug('token refreshed in _completeLogin', response.status);

        Auth.user = userTokens.decode();

        const wasLoggedIn = Auth.isLoggedIn();
        if(!wasLoggedIn) {
          $rootScope.$broadcast(eventPrefix + 'loggedIn' );
        }

        // Must be run after login, in order to ensure that requestes that 
        // were rejected due to 401' are retried
        // Should not be within Auth.isLoggedIn() check, because user may
        // have been isLoggedIn(), but had an expired token
        // which would have led to unauthorized requests
        authHttpService.loginConfirmed();

        // Reset the number of attempts; this gets iterated only if refresh fails
        _tokenRefreshAttempts = 0;

        deferred.resolve(Auth.user);
      }, (response) => {
        if(response.status > 400 && response.status < 500) {
          $log.debug('token expired in _completeLogin', response.status, response.data);
          
          if(response.status === 401) {
            if(sessionExpiredTimeout) {
              $timeout.cancel(sessionExpiredTimeout);
            }

            $rootScope.$broadcast(eventPrefix + 'sessionExpired', true);

            const message = response.data || 'Your user session expired ... please log back in.';
            // No need to iterate _tokenRefreshAttempts, because this is the only
            // possible attempt; Auth.logout() will clear _tokenRefreshAttempts
            // Force logout; there is no possibility of the user being authenticated
            Auth.logout(message, true);

            return deferred.reject(response);
          }
          //Auth.logout( response.error == - 1 ? errors.sessionExpiredAndOffline : errors.sessionExpired, true);
        } else {
          $log.warn('server couldn\'t be reached, or had a different issue', response);
        }

        // TODO: Implement retry mechanism ; I believe this is the point
        // where we get an infinite loop of re-attempts, because we're not trackign
        // _tokenRefreshAttempts right now.

        _tokenRefreshAttempts++;
        // In either case, we cannot verify that the user has a valid session,
        // therefore log out
        if(_tokenRefreshAttempts > _maxLoginAttempts) {
          Auth.logout("Maximum login retry attempts reached", true);
        }
        
        deferred.reject(response);
      } );

      return deferred.promise;
    }
    
    deferred.reject(new Error('Not logged in'));
    return deferred.promise;
  }

  var _resetUri = _authBaseRoute + 'reset';
  Auth.sendResetLink = function(email) {
    return $http.get(_resetUri, {
      params: {email: encodeURIComponent(email) },
    });
  };

  Auth.resetPassword = function(resetToken, newPassword) {
    // TODO: we could auto log in the user
    return $http.post(_resetUri, {
      token: encodeURIComponent(resetToken),
      password: newPassword,
    });
  };
  /**
   * Delete access token and user info
   *
   * @param  {Function}
   */
  Auth.logout = function logout(message, force) {
    if (!force && !Auth.isLoggedIn() ) {
      return $log.error('called Auth.logout, but user is not logged in');
    }
    
    Auth.clear();
    authHttpService.loginCancelled();
    $log.debug("broadcasting logout", eventPrefix + 'loggedOut');
    $rootScope.$broadcast(eventPrefix + 'loggedOut', message ? new Error(message) : message );
  };

  Auth.register = function register(registration, callback) {
    var saveUser = function saveUser(reg) {
      return User.register(reg, function(data) {
        if (Auth.isLoggedIn() ) {
          Auth.logout();
        }
        return _completeLoginAsync(data);
      }, (err) => {
        if (Auth.isLoggedIn() ) {
          Auth.logout(err.data);
        }
        return $q.reject(err);
      }).$promise;
    }.bind(null, registration, callback || angular.noop);

    if (_loginPromise) {
      return _loginPromise.finally(function() {
        saveUser();
      });
    }
    return saveUser();
  };

  /**
   * Check user status, role
   *
   * @return {Boolean}
   */
  Auth.isLoggedIn = function isLoggedIn() {
    return !!Object.keys(Auth.user).length;
  };

  Auth.isGuest = function isGuest() {
    return Auth.user.role === 'guest';
  };

  Auth.isAdmin = function isAdmin() {
    return Auth.user.role === 'admin';
  };

  /* The first time we boot up, we want to make sure that we have a token*/
  /* @ returns { {Object} user, {Str} token} */
 // Auth.initAsync = _initAsync;

  //Auth.logoutIfUnauth = _logoutIfUnauth;

  Auth.verifyLoggedInAsync = _completeLoginAsync;
  // 403 is a forbidden response; if this occurs during refresh it means
  // the refresh token is bad, or the id token is malformed

  // function _logoutIfUnauth(response) {
  //   $log.debug('called logoutIfUnauth');

  //   if (response.status === 403) {
  //     _tokenRefreshAttempts = 0;
  //     return Auth.logout(errors.sessionExpired, true);
  //   }

  //   ++_attempts; //to allow broadcast, iterate here not in if statemetn
  //   if (_attempts > 10) {
  //     _tokenRefreshAttempts = 0;
  //     return Auth.logout(errors.attemptsExceeded, true);
  //   }
  //   //$rootScope.$broadcast(eventPrefix + 'loginAttemptFailed');
  // }

  // function _verifyLoggedInAsync() {
  //   // if (_loginPromise) {return _loginPromise; }

  //   var deferred = $q.defer();

  //   _completeLoginAsync().then(function() {
  //     // success
  //     deferred.resolve();
  //   }, function () {
  //     deferred.reject();
  //   })

  //   return deferred.promise;
  // }

  return Auth; // all angular singleton
}